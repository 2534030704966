<template>
  <div class="page sonuc">
    <div class="formcontainer">
      <div class="formbox">
        <h2>Başvuru Durumu</h2>

        <div class="formpart">
          <div class="formfull">
            <div class="durumbox">
              <div class="text">
                <div v-if="SUBMITTED || EDITED" class="details">
                  <h4 class="yello_d">Onay Bekliyor</h4>
                </div>
                <div v-if="CONFIRMED" class="details">
                  <h4>Onaylandı</h4>
                </div>
                <div class="details">
                  <h4 class="red" v-if="REJECTED">
                    <img src="@/assets/img/ico_exclamination.svg" alt />İşlem
                    Bekleniyor:
                  </h4>
                  <div v-if="REJECTED && rejectedFiles.length > 0">
                    <p v-for="(file, index) in rejectedFiles" :key="index">
                      {{ belge_turu_map[file.docType].str }}
                      <b>"{{ file.fileName }}"</b>:
                      <span style="color: red">{{ file.rejectionReason }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <bilgi-boxes></bilgi-boxes>

        <div v-if="application.appType !== 'EDEVLET'" class="formpart">
          <div class="formfull">
            <h3>Taahhütname</h3>

            <div class="filerow formpart filekt">
              <div
                v-for="(file, index) in sorted_fileSet"
                :key="index"
                class="fileline"
                :class="file_status_map[file.fileStatus].box_class"
              >
                <div>
                  <img src="@/assets/img/file_pdf.svg" alt />
                  <div class="filetype">
                    {{ belge_turu_map[file.docType].str }}
                  </div>
                </div>

                <div class="filename">{{ file.fileName }}</div>

                <label
                  class="changebtn"
                  :style="file_status_map[file.fileStatus].style"
                  :for="'degistir_' + file.id"
                  >{{ file_status_map[file.fileStatus].label }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <div v-if="application.brandSet && application.brandSet.length > 0">
          <div
            class="izintable"
            v-for="(item, index) in getBrands"
            :key="index"
          >
            <div class="plr">
              <form>
                <h3 v-if="index === 0">Marka Bilgileri</h3>
                <div class="izinline">
                  <marka-show :brand="item" :editButtons="false" />
                </div>
              </form>
            </div>
          </div>
        </div>

        <sonraki-bar :label="label" @next="NEXT" :friction="200" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import MarkaShow from "./genel/MarkaShow";
import SonrakiBar from "./genel/SonrakiBar";
import BilgiBoxes from "./genel/BilgiBoxes";

export default {
  name: "BasvuruSorgulama",
  components: {
    BilgiBoxes,
    MarkaShow,
    SonrakiBar,
  },

  data() {
    return {
      belge_turu_map: require("../../../misc/enums").ENUM_BELGE_TURU,
      file_status_map: require("../../../misc/enums").ENUM_BELGE_STATUS_INFO,
    };
  },

  mounted() {
    this.BELGE_LISTESI_UPDATE();
  },

  computed: {
    ...mapState("applicant", ["application"]),
    ...mapGetters("applicant", ["getBrands"]),

    next() {
      return this.rejectedFiles.length === 0;
    },

    rejectedFiles() {
      return this.application.fileSet.filter((e) => e.fileStatus == "REJECTED");
    },

    SUBMITTED() {
      return (
        this.application.step === "FINAL_SUBMITTED_TO_AGENT" ||
        this.application.step === "INITIAL_SUBMITTED_TO_AGENT"
      );
    },

    REJECTED() {
      return this.application.step === "FINAL_AGENT_REJECTED";
    },

    CONFIRMED() {
      return this.application.step === "FINAL_AGENT_CONFIRMED";
    },

    EDITED() {
      return this.application.step === "FINAL_DOCUMENTS_EDITED";
    },

    label() {
      if (this.SUBMITTED || this.CONFIRMED) return "Başvuru Durumu";
      if (this.REJECTED) return "Markaları Düzenle";
    },

    sorted_fileSet() {
      return this.application.fileSet
        .sort((a, b) =>
          this.belge_turu_map[a.docType].order >
          this.belge_turu_map[b.docType].order
            ? 1
            : -1
        )
        .filter((e) => e.docType === "HIZMET_SOZLESMESI");
    },
  },

  methods: {
    ...mapActions("applicant", [
      "belge_listesi_cek",
      "belgeleri_tekrar_onayla",
    ]),

    ...mapMutations("applicant", ["SET_APPLICATION"]),

    BELGE_LISTESI_UPDATE() {
      this.belge_listesi_cek().then((res) => {
        this.SET_APPLICATION(res.payload);
      });
    },

    NEXT() {
      if (this.REJECTED) {
        this.belge_listesi_cek().then((res) => {
          this.SET_APPLICATION(res.payload);
          this.$router.push("/hizmet-saglayici/basvuru/bilgi-girisi");
        });
      } else {
        this.belge_listesi_cek().then((res) => {
          this.SET_APPLICATION(res.payload);
          this.$router.push("/hizmet-saglayici/basvuru/sonuc");
        });
      }

      // this.belgeleri_tekrar_onayla().then(res => {
      //   this.SET_APPLICATION(res.payload);
      //   this.$router.push("/hizmet-saglayici/basvuru/bilgi-girisi");
      // });
    },
  },
};
</script>

<style>
.durumbox {
  background: #eee;
  border-radius: 0.5vw;
  padding: 1vw;
  margin: 2vw 0;
}

.izintable h3 {
  margin-bottom: 2.5vh;
}
</style>
